import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { GlobalStyles } from 'trusttoken-ui/styles/GlobalStyles';
import { customerState, applicationState } from 'data/state';
import { isAuthenticated } from 'lib/auth';
import { isDeployedEnv } from 'lib/env';
import { redirectToAdmin } from 'lib/routing';
import { ErrorBoundary, noRegisteredCustomerError } from 'lib/ErrorBoundary';
import { TopBar } from 'components/TopBar';
import { PageFooter } from 'components/PageFooter';
import { PageLoader } from 'components/layout/PageLoader';
import { Routes } from 'pages/Routes';
import { AccountDeactivated } from 'pages/AccountDeactivated';
import { attachUserEmailToErrors } from 'lib/sentry';
import faviconUrl from 'images/new/tusd-logo-app.svg';
import { ConfigProvider, App } from 'antd';
import { colors } from 'styles/css/color';
import Loading from 'components/Loading';
import '~styles/css/antd.scss';

const GET_USER_CONTEXT = gql`
  {
    getMyUser {
      id
      email
      roles {
        name
      }
    }
    getMyPerson {
      id
      firstName
      lastName
      address {
        country
      }
    }
    getMyCustomer {
      id
      accountType
      accountState {
        isActive
        canTransact
      }
      approvedAt
      deactivatedAt
      deactivationReason
      organization {
        id
        name
      }
      application {
        id
        emailVerified
        status
        stage {
          type
        }
        pendingRejectedAt
        applicationBucket {
          truecurrency
        }
        latestSubmission {
          rejectionReasons {
            code
            component
            message
          }
          pendingRejectedAt
        }
      }
    }
  }
`;

const ContentContainer = styled.div`
  min-height: calc(100vh - 64px - 85px - 20px);
  overflow: auto;
  position: relative;
  background: #fff;
  padding: 0 0 20px;
`;

const FullPageContainer = styled.div`
  height: 100vh;
  position: relative;
  background: #fff;
`;

function userIsAdmin(user) {
  if (user) {
    const roleNames = _.map(user.roles, 'name');
    if (roleNames.includes('admin') || roleNames.includes('kyc_aml_officer')) {
      return true;
    }
  }
}

// simple wrapper to return TopBar and Routes together
// TopBar may depend on some API data
function TopBarAndRoutes(props) {
  const NO_TOPBAR_FOOTER = ['/signup-or-signin'];
  const { location } = props;
  const isShowNavFoot = !NO_TOPBAR_FOOTER.includes(location.pathname);
  return (
    <>
      {isShowNavFoot ? (
        <>
          <TopBar />
          <ContentContainer>
            <Routes />
          </ContentContainer>
          <PageFooter />
        </>
      ) : (
        <FullPageContainer>
          <Routes />
        </FullPageContainer>
      )}
    </>
  );
}

// very top-level routes, let us do some custom pages without TopBar
// move this out of here later
const TopLevelRoutes = () => (
  <ErrorBoundary>
    <Suspense fallback={<Loading fullpage />}>
      <Switch>
        <Route component={TopBarAndRoutes} />
      </Switch>
    </Suspense>
  </ErrorBoundary>
);

function Page(props) {
  // If not authenticated, don't make API calls
  if (!isAuthenticated()) {
    return <TopLevelRoutes />;
  }

  // Before loading anything, make some API calls
  const { loading, error, data } = useQuery(GET_USER_CONTEXT, {
    errorPolicy: 'all',
  });

  if (loading) {
    return <PageLoader />;
  }

  const user = data.getMyUser;

  if (userIsAdmin(user)) {
    redirectToAdmin();
    return;
  }

  const person = data.getMyPerson;
  const customer = data.getMyCustomer;
  let application;

  if (!person && !customer) {
    return noRegisteredCustomerError();
  }

  if (error) {
    throw error;
  }

  if (customer) {
    application = customer.application;
  }

  if (person && !person.address) {
    person.address = { country: null };
  }

  attachUserEmailToErrors(user.email);

  // This code is all kind of hacky, esp the "state" stuff, we should refactor or scrap much of this
  // TODO: move this to React context
  if (customer) {
    customerState.customer = customer;

    const organization = customer.organization;
    if (organization) {
      customerState.organization = organization;
    }

    if (customer.deactivatedAt) {
      // If user is deactivated, don't let them do anything else
      return <AccountDeactivated deactivationReason={customer.deactivationReason || ''} />;
    }

    const accountState = customer.accountState;

    if (accountState) {
      customerState.isAccountActive = accountState.isActive;
      customerState.canTransact = accountState.canTransact;
    }
  }

  if (person) {
    customerState.person = person;
  }

  if (application) {
    applicationState.application = application;
    applicationState.emailVerified = application.emailVerified;
    applicationState.stage = application.stage.type;
    applicationState.truecurrency = application.applicationBucket.truecurrency;
    applicationState.rejectionReasons =
      application?.latestSubmission?.rejectionReasons || [];
    applicationState.pendingRejectedAt =
      application?.latestSubmission?.pendingRejectedAt || '';
  }

  return <TopLevelRoutes />;
}

function Root() {
  const favicon = faviconUrl;
  return (
    <ErrorBoundary>
      <Helmet>
        <title>TrueUSD</title>(
        {isDeployedEnv() && (
          <script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=b5d6bffa-aaa2-4974-bdbd-593b23ec387b"
          />
        )}
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <GlobalStyles />
      <ConfigProvider
        theme={{
          token: colors,
        }}
      >
        <App>
          <Page />
        </App>
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export { Root };

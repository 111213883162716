export const colors = {
  white: '#ffffff',
  black: '#000000',
  red: {
    default: '#db4254',
    light: '#ef5668',
    dark: '#c02a3a',
  },
  blue: {
    default: '#212959',
    lightest: '#EBECFF',
    lighter: '#4e5aa0',
    light: '#353d6d',
    dark: '#161c3e',
    darker: '#212959',
  },
  neutral: {
    default: '#aaaaaa',
    lighter: '#d9d9d9',
    light: '#d7d7d7',
    dark: '#8B8EA5',
    darker: '#646464',
  },
  primary: {
    default: '#db4254',
    light: '#ef5668',
    dark: '#c02a3a',
  },
  background: '#f5f5f5',
  success: '#db4254',
};

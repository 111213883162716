import { action, computed, observable, toJS } from 'mobx';
import { BENEFICIARIES } from 'pages/dashboard/PurchaseInstructions/constants';

interface ICustomerData {
  bankingPartner: string
  isUSUser: boolean
  address: {
    country: string
  }
  operatingAddress: {
    country: string
  }
  governmentIdCountry: string
  citizenshipCountry: string
  regionOfFormation: {
    country: string
  }
}
export class Store {
  @observable signupData = {};

  @computed get signupDataJson() {
    return toJS(this.signupData);
  }

  @action public updateSignupData = (obj) => {
    this.signupData = {
      ...this.signupData,
      ...obj,
    };
  };

  @action public clearSignupData = () => {
    this.signupData = {};
  };

  @observable customerData: ICustomerData = {
    bankingPartner: '',
    isUSUser: false,
    address: {
      country: ''
    },
    operatingAddress: {
      country: ''
    },
    governmentIdCountry: '',
    citizenshipCountry: '',
    regionOfFormation: {
      country: ''
    },
  }

  @computed get isDisabeldMintAndRedeem() {
    return this.isPrimeTrust || this.noBankingPartner || this.customerData.isUSUser
  }

  @computed get isDisabeldWallet() {
    return this.customerData.isUSUser
  }

  @computed get isPrimeTrust() {
    return this.customerData.bankingPartner === BENEFICIARIES.PRIME_TRUST
  }

  @computed get noBankingPartner() {
    return this.customerData.bankingPartner === ''
  }

  @computed get isCub() {
    return this.customerData.bankingPartner === BENEFICIARIES.CUB
  }

  @computed get isDeltec() {
    return this.customerData.bankingPartner === BENEFICIARIES.Deltec
  }

  @computed get isFlowBank() {
    return this.customerData.bankingPartner === BENEFICIARIES.FlowBank
  }

  @action public updateCustomerData = (obj) => {
    this.customerData = {
      ...this.customerData,
      ...obj,
    };
  };

  @action public clearCustomerData = () => {
    this.customerData = {
      bankingPartner: '',
      isUSUser: false,
      address: {
        country: ''
      },
      operatingAddress: {
        country: ''
      },
      governmentIdCountry: '',
      citizenshipCountry: '',
      regionOfFormation: {
        country: ''
      },
    }
  }
}

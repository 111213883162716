import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'trusttoken-ui/styles/theme';
import { applicationState } from 'data/state';
import { FloatingShareButtons } from 'components/FloatingShareButtons';

let Center = styled.div`
  /*
    See 'TopBar/index.tsx' for what this margin is trying to match.
    Will want to share a single source of truth in the future.
  */
  color: ${theme.colors.black};
  background: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 84px;
`;

let CenterBoxer = styled.div`
  width: 1200px;
  padding: 30px 40px;
  display: flex;
  justify-content: space-between;
`;

// Copied link style from Figma doc
const Link = styled.a`
  text-decoration: none;
  font-style: normal;
  color: rgba(0, 0, 0, 0.85);
  margin-right: 32px;
  &:last-child {
    margin-right: 0;
  }
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`}
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  &:hover {
    color: #1a5aff;
  }
`;

export function PageFooter({ signInSignUpFoot = false }) {
  /*
    Opening in "_blank" to prevent users from losing their progress if they click on one of these
    links while filling out a form.
  */

  if (signInSignUpFoot) {
    Center = styled(Center)`
      border-top: none;
      background: transparent;
    `;
    CenterBoxer = styled(CenterBoxer)`
      width: 100%;
    `;
  } else {
    Center = styled(Center)`
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
    `;
    CenterBoxer = styled(CenterBoxer)`
      width: 1200px;
    `;
  }

  return (
    <Center>
      <CenterBoxer>
        <FloatingShareButtons />
        <span>
          <Link href="/terms-of-use" target="_blank">
            Terms of Use
          </Link>
          <Link href="/privacy-policy" target="_blank">
            Privacy Policy
          </Link>
          <Link href="https://trueusd.zendesk.com" target="_blank">
            Help Center
          </Link>
        </span>
      </CenterBoxer>
    </Center>
  );
}

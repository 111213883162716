import * as React from 'react';
import { runInAction, autorun, toJS } from 'mobx';
import { inject, Observer } from 'mobx-react';

export function withStore(Component) {
  function ObservableComponent(props) {
    return (
      <Observer
        render={() => <Component {...props} />}
      />
    );
  }
  return inject('store')(ObservableComponent);
}

export function rehydrate(store) {
  const init = (data) => {
    if (typeof data === 'object') {
      Object.keys(data).forEach((key) => { store[key] = data[key]; });
    }
  };

  runInAction(() => {
    try {
      const parsed = JSON.parse(localStorage.getItem('val_web_store'));
      if (parsed) { init(parsed); }
    } catch {
      // Ignore localstorage parse errors
    }
  });
}

export function autosave(store) {
  autorun(() => {
    console.log('autosaving...');
    localStorage.setItem('val_web_store', JSON.stringify(toJS(store)));
  });
}

import * as React from 'react';

import { PageDescription } from 'components/PageDescription';
import { ContentSection } from 'components/ContentSection';
import { colors } from 'styles/css/color';

const AccountDeactivated = ({ deactivationReason }: { deactivationReason?: string }) => (
  <ContentSection centered marginTop="150px" maxWidth="600px">
    <PageDescription>
      Your account has been deactivated.
      <br />
      {deactivationReason && (
        <span style={{ color: colors.colorError }}>{deactivationReason}</span>
      )}
      <br />
      If you have any questions, please contact <a href="mailto:support@trueusd.com">support@trueusd.com</a>
    </PageDescription>
  </ContentSection>
);

export { AccountDeactivated };

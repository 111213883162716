import 'babel-polyfill';
import createBrowserHistory from 'history/createBrowserHistory';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import * as ReactGA from 'react-ga';
import { Root } from 'pages/Root';
import { createClient } from './graphql/apollo';
import { theme } from 'trusttoken-ui/styles/theme';
import { watrTheme } from 'trusttoken-ui/styles/watr-theme';
import { Store } from 'data/store';
import { applicationState } from 'data/state';
import { rehydrate, autosave } from 'lib/store';
import { startTrackingErrors } from 'lib/sentry';
import './i18n';

startTrackingErrors();
ReactGA.initialize(process.env.REACT_APP_GA_PROPERTY_ID);

const apolloClient = createClient();
const store = (window as any).store = new Store();

const history = createBrowserHistory();

ReactGA.pageview(history.location.pathname);
history.listen((location, action) => {
  ReactGA.pageview(location.pathname);
});

// figure out theme

let themeToUse = theme;

if (process.env.REACT_APP_ENV === 'production') {
  const hostname = window.location.hostname;
  const watr = hostname.includes('watrid');
  if (watr) {
    applicationState.watr = true;
    themeToUse = watrTheme;
  }
} else {
  const hostname = window.location.hostname;
  const watr = hostname.includes('watrid');
  // const watr = true;
  if (watr) {
    applicationState.watr = true;
    themeToUse = watrTheme;
  }
}

ReactDOM.render(
  <ThemeProvider theme={themeToUse}>
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <Router history={history}>
          <Root />
        </Router>
      </ApolloProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);

[rehydrate, autosave].forEach((fn) => fn(store));
const LOGIN_REDIRECT_URL = process.env.REACT_APP_LOGIN_REDIRECT_URL || 'https://login.tusd.io';
const ADMIN_URL = process.env.REACT_APP_ADMIN_URL || 'https://partner.tusd.io/internal';
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

const WATR_LOGIN_REDIRECT_URL = process.env.WATR_LOGIN_REDIRECT_URL || 'https://login.watrid.com';

export {
  LOGIN_REDIRECT_URL,
  ADMIN_URL,
  SENTRY_DSN,
  WATR_LOGIN_REDIRECT_URL,
};

// Put some global in-memory state stuff, that we don't want in localstorage/Mobx just yet, here
// This pattern is a bit ad-hoc but we'll figure out how to clean it up later

const customerState = {
  customer: null,
  person: null,
  organization: null,
  isAccountActive: false,
  canTransact: false,
  blockchainAccounts: [],
};

const applicationState = {
  application: null,
  emailVerified: false,
  stage: null,
  watr: false,
  truecurrency: false,
  bucket: null,
  rejectionReasons: [],
  pendingRejectedAt: null
};

// function isBinanceUser() {
//   // placeholder for now..
//   return false;
// }

function isAvalancheUser() {
  // placeholder for now..
  return false;
}

function isTronUser() {
  // placeholder for now..
  return false;
}

function isBscUser() {
  // placeholder for now..
  return false;
}

export {
  customerState,
  applicationState,
  // isBinanceUser,
  isAvalancheUser,
  isTronUser,
  isBscUser,
};

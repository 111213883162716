import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';
import React from 'react';

function createIcon(element: React.ReactElement) {
  function Component() {
    return element;
  }
  return function (props: Partial<CustomIconComponentProps>) {
    return <Icon component={Component} {...props} />;
  };
}

function createSvgIcon(
  createPath: (options: { fillColor: string }) => React.ReactNode,
  options?: {
    viewBox?: string;
    width?: string;
    height?: string;
    fillColor?: string;
  }
) {
  return createIcon(
    <svg
      viewBox={options?.viewBox || '0 0 1024 1024'}
      width={options?.width || '1em'}
      height={options?.height || '1em'}
    >
      {createPath({ fillColor: options?.fillColor || 'currentColor' })}
    </svg>
  );
}

export const PendingIcon = createSvgIcon(({ fillColor }) => {
  return (
    <>
      <path
        d="M114.5856 951.04h298.24v-71.68H186.2656v-747.52h593.92v271.36h71.68v-343.04h-737.28v890.88z"
        fill={fillColor}
      ></path>
      <path
        d="M662.4256 311.04h-358.4v-71.68h358.4v71.68zM508.8256 490.24h-204.8v-71.68h204.8v71.68zM668.8256 554.24a168.96 168.96 0 1 0 0 337.92 168.96 168.96 0 0 0 0-337.92z m-240.64 168.96a240.64 240.64 0 1 1 481.28 0 240.64 240.64 0 0 1-481.28 0z"
        fill={fillColor}
      ></path>
      <path
        d="M629.76 588.8h71.68v131.4304l82.5856 41.3184-32.0512 64.1024-122.2144-61.0816V588.8z"
        fill={fillColor}
      ></path>
    </>
  );
});

export const PendingApproveIcon = createSvgIcon(({ fillColor }) => {
  return (
    <>
      <path
        d="M699.754 509.754c63.872 0 115.65 51.778 115.65 115.65 0 63.87-51.778 115.649-115.65 115.649-63.87 0-115.649-51.778-115.649-115.65 0-63.87 51.778-115.649 115.65-115.649z m0 71.86c-24.184 0-43.79 19.605-43.79 43.79 0 24.184 19.606 43.789 43.79 43.789 24.185 0 43.79-19.605 43.79-43.79 0-24.184-19.605-43.789-43.79-43.789z"
        fill={fillColor}
      ></path>
      <path d="M668.316 725.333h66.246v70.737h-66.246z" fill={fillColor}></path>
      <path
        d="M862.561 771.368c19.844 0 35.93 16.087 35.93 35.93V924.07c0 19.844-16.086 35.93-35.93 35.93H540.316c-19.844 0-35.93-16.086-35.93-35.93V807.298c0-19.843 16.086-35.93 35.93-35.93H862.56z m-35.93 71.86H576.247v44.912h250.386v-44.912z"
        fill={fillColor}
      ></path>
      <path
        d="M777.228 64c41.746 0 75.666 33.503 76.34 75.088l0.01 1.263v269.2c0 8.837-7.162 16-16 16h-48.841c-8.837 0-16-7.163-16-16V144.842H206.842v705.123h163.685c8.837 0 16 7.163 16 16v48.842c0 8.837-7.163 16-16 16H202.351c-41.746 0-75.667-33.503-76.34-75.088l-0.011-1.263V140.351c0-41.746 33.503-75.667 75.088-76.34l1.263-0.011h574.877z"
        fill={fillColor}
      ></path>
      <path
        d="M658.772 244.772c18.293 0 33.123 14.83 33.123 33.123s-14.83 33.123-33.123 33.123H316.316c-18.293 0-33.123-14.83-33.123-33.123s14.83-33.123 33.123-33.123h342.456zM454.421 391.86c18.293 0 33.123 14.83 33.123 33.122 0 18.294-14.83 33.123-33.123 33.123h-135.86c-18.293 0-33.122-14.83-33.122-33.123s14.83-33.122 33.122-33.122h135.86z"
        fill={fillColor}
      ></path>
    </>
  );
});

export const RiskIcon = createSvgIcon(({ fillColor }) => {
  return (
    <>
      <path
        d="M475.428571 256v292.571429c0 21.942857 14.628571 36.571429 36.571429 36.571428s36.571429-14.628571 36.571429-36.571428v-292.571429c0-21.942857-14.628571-36.571429-36.571429-36.571429s-36.571429 14.628571-36.571429 36.571429zM512 658.285714c-21.942857 0-36.571429 14.628571-36.571429 36.571429s14.628571 36.571429 36.571429 36.571428 36.571429-14.628571 36.571429-36.571428S533.942857 658.285714 512 658.285714z m424.228571-577.828571C782.628571 21.942857 658.285714 0 512 0S241.371429 21.942857 87.771429 80.457143c-7.314286 7.314286-14.628571 14.628571-14.628572 29.257143v460.8c0 190.171429 270.628571 373.028571 431.542857 453.485714h14.628572c160.914286-80.457143 431.542857-263.314286 431.542857-453.485714V109.714286c0-14.628571-7.314286-21.942857-14.628572-29.257143zM877.714286 563.2c0 124.342857-182.857143 277.942857-365.714286 380.342857-182.857143-95.085714-365.714286-256-365.714286-380.342857V138.971429C270.628571 95.085714 380.342857 73.142857 512 73.142857s241.371429 21.942857 365.714286 65.828572v424.228571z"
        fill={fillColor}
      ></path>
    </>
  );
});

export const SealIcon = createSvgIcon(({ fillColor }) => {
  return (
    <path
      d="M512 64a224 224 0 0 1 133.248 404.096l-5.248 3.712L639.968 544H768a160 160 0 0 1 159.84 153.056L928 704v96H96v-96a160 160 0 0 1 160-160h128v-72.192l-5.216-3.712A223.776 223.776 0 0 1 288.16 296.64L288 288a224 224 0 0 1 224-224z m0 64a160 160 0 0 0-80 298.624l16 9.248V608H256a96 96 0 0 0-96 96v32h704v-32a96 96 0 0 0-90.368-95.84L768 608h-192L576 435.84l16-9.248A160 160 0 0 0 512 128zM96 864h832v32a32 32 0 0 1-32 32H128a32 32 0 0 1-32-32v-32z"
      stroke={fillColor}
    ></path>
  );
});

export const MouseIcon = createSvgIcon(({ fillColor }) => {
  return (
    <path
      d="M554.666667 85.333333h-85.333334C328.149333 85.333333 213.333333 200.149333 213.333333 341.333333v341.333334c0 141.184 114.816 256 256 256h85.333334c141.184 0 256-114.816 256-256V341.333333c0-141.184-114.816-256-256-256z m-85.333334 85.333334v256H298.666667V341.333333c0-94.122667 76.544-170.666667 170.666666-170.666666z m256 512c0 94.122667-76.544 170.666667-170.666666 170.666666h-85.333334c-94.122667 0-170.666667-76.544-170.666666-170.666666v-170.666667h426.666666v170.666667z m-170.666666-256V170.666667c94.122667 0 170.666667 76.544 170.666666 170.666666v85.333334h-170.666666z"
      stroke={fillColor}
    ></path>
  );
});

// Upload component
export const UploadIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM13 9V16H11V9H6L12 3L18 9H13Z"
        fill={fillColor}
      ></path>
    );
  },
  { viewBox: '0 0 24 24' }
);

export const ImageIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M2.9918 21C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918ZM20 15V5H4V19L14 9L20 15ZM20 17.8284L14 11.8284L6.82843 19H20V17.8284ZM8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11Z"
        fill={fillColor}
      ></path>
    );
  },
  { viewBox: '0 0 24 24' }
);

export const PdfIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
        fill={fillColor}
      ></path>
    );
  },
  { viewBox: '0 0 24 24' }
);

export const WordIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M17 19H20V4.99997H17V2.99997H21C21.5523 2.99997 22 3.44769 22 3.99997V20C22 20.5523 21.5523 21 21 21H17V19ZM2.85858 2.87732L15.4293 1.0815C15.7027 1.04245 15.9559 1.2324 15.995 1.50577C15.9983 1.52919 16 1.55282 16 1.57648V22.4235C16 22.6996 15.7761 22.9235 15.5 22.9235C15.4763 22.9235 15.4527 22.9218 15.4293 22.9184L2.85858 21.1226C2.36593 21.0522 2 20.6303 2 20.1327V3.86727C2 3.36962 2.36593 2.9477 2.85858 2.87732ZM4 4.73457V19.2654L14 20.694V3.30599L4 4.73457ZM11 7.99997H13V16H11L9 14L7 16H5V7.99997H7L7.01083 13L9 11L11 12.989V7.99997Z"
        fill={fillColor}
      ></path>
    );
  },
  { viewBox: '0 0 24 24' }
);

export const RemoveIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
        fill={fillColor}
      ></path>
    );
  },
  { viewBox: '0 0 24 24' }
);

export const EyeIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z"
        fill={fillColor}
      ></path>
    );
  },
  { viewBox: '0 0 24 24' }
);

export const BankIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        id="Vector"
        d="M1.66666 16.6667H18.3333V18.3334H1.66666V16.6667ZM3.33332 10.0001H4.99999V15.8334H3.33332V10.0001ZM7.49999 10.0001H9.16666V15.8334H7.49999V10.0001ZM10.8333 10.0001H12.5V15.8334H10.8333V10.0001ZM15 10.0001H16.6667V15.8334H15V10.0001ZM1.66666 5.83341L9.99999 1.66675L18.3333 5.83341V9.16675H1.66666V5.83341ZM9.99999 6.66675C10.4602 6.66675 10.8333 6.29365 10.8333 5.83341C10.8333 5.37318 10.4602 5.00008 9.99999 5.00008C9.53974 5.00008 9.16666 5.37318 9.16666 5.83341C9.16666 6.29365 9.53974 6.66675 9.99999 6.66675Z"
        fill="black"
        fill-opacity="0.45"
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const AccountIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M10.0001 1.66667C14.6001 1.66667 18.3334 5.40001 18.3334 10C18.3334 14.6 14.6001 18.3333 10.0001 18.3333C5.40008 18.3333 1.66675 14.6 1.66675 10C1.66675 5.40001 5.40008 1.66667 10.0001 1.66667ZM5.01951 12.8469C6.24244 14.6724 8.07934 15.8333 10.1332 15.8333C12.187 15.8333 14.0239 14.6724 15.2468 12.8469C13.9072 11.5977 12.1094 10.8333 10.1332 10.8333C8.15692 10.8333 6.35921 11.5977 5.01951 12.8469ZM10.0001 9.16667C11.3808 9.16667 12.5001 8.04738 12.5001 6.66667C12.5001 5.28596 11.3808 4.16667 10.0001 4.16667C8.61933 4.16667 7.50008 5.28596 7.50008 6.66667C7.50008 8.04738 8.61933 9.16667 10.0001 9.16667Z"
        fill="black"
        fill-opacity="0.45"
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const UserIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        id="Vector"
        d="M10.8333 11.7182V18.3333H3.33334C3.33334 14.6513 6.31811 11.6666 10 11.6666C10.2823 11.6666 10.5603 11.6841 10.8333 11.7182ZM10 10.8333C7.23751 10.8333 5.00001 8.59575 5.00001 5.83325C5.00001 3.07075 7.23751 0.833252 10 0.833252C12.7625 0.833252 15 3.07075 15 5.83325C15 8.59575 12.7625 10.8333 10 10.8333ZM14.8274 16.5951L17.7737 13.6488L18.9522 14.8273L14.8274 18.9521L11.8812 16.0058L13.0597 14.8273L14.8274 16.5951Z"
        fill="black"
        fill-opacity="0.45"
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const WalletIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M18.3375 4.99982H12.5041C9.74271 4.99982 7.50411 7.23839 7.50411 9.99982C7.50411 12.7612 9.74271 14.9998 12.5041 14.9998H18.3375V16.6665C18.3375 17.1267 17.9644 17.4998 17.5041 17.4998H2.50411C2.04388 17.4998 1.67078 17.1267 1.67078 16.6665V3.33315C1.67078 2.87291 2.04388 2.49982 2.50411 2.49982H17.5041C17.9644 2.49982 18.3375 2.87291 18.3375 3.33315V4.99982ZM12.5041 6.66648H19.1708V13.3332H12.5041C10.6631 13.3332 9.17079 11.8407 9.17079 9.99982C9.17079 8.15887 10.6631 6.66648 12.5041 6.66648ZM12.5041 9.16649V10.8332H15.0041V9.16649H12.5041Z"
        fill={fillColor}
        fill-opacity="0.45"
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const LogoutIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        id="Vector"
        d="M4.16668 18.3334C3.70644 18.3334 3.33334 17.9603 3.33334 17.5001V2.50008C3.33334 2.03985 3.70644 1.66675 4.16668 1.66675H15.8333C16.2936 1.66675 16.6667 2.03985 16.6667 2.50008V17.5001C16.6667 17.9603 16.2936 18.3334 15.8333 18.3334H4.16668ZM12.5 13.3334L16.6667 10.0001L12.5 6.66675V9.16675H7.50001V10.8334H12.5V13.3334Z"
        fill="black"
        fill-opacity="0.45"
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const CheckBoxCircleIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M10.0001 18.3333C5.39771 18.3333 1.66675 14.6023 1.66675 9.99999C1.66675 5.39761 5.39771 1.66666 10.0001 1.66666C14.6024 1.66666 18.3334 5.39761 18.3334 9.99999C18.3334 14.6023 14.6024 18.3333 10.0001 18.3333ZM9.16891 13.3333L15.0615 7.44076L13.883 6.26226L9.16891 10.9763L6.81192 8.61924L5.63341 9.79782L9.16891 13.3333Z"
        fill={fillColor}
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const ErrorWarningIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M10.0001 18.3333C5.39771 18.3333 1.66675 14.6023 1.66675 9.99999C1.66675 5.39761 5.39771 1.66666 10.0001 1.66666C14.6024 1.66666 18.3334 5.39761 18.3334 9.99999C18.3334 14.6023 14.6024 18.3333 10.0001 18.3333ZM9.16675 12.5V14.1667H10.8334V12.5H9.16675ZM9.16675 5.83332V10.8333H10.8334V5.83332H9.16675Z"
        fill={fillColor}
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const MailIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M2.50008 2.5H17.5001C17.9603 2.5 18.3334 2.8731 18.3334 3.33333V16.6667C18.3334 17.1269 17.9603 17.5 17.5001 17.5H2.50008C2.03985 17.5 1.66675 17.1269 1.66675 16.6667V3.33333C1.66675 2.8731 2.03985 2.5 2.50008 2.5ZM16.6667 6.0316L10.0599 11.9483L3.33341 6.01328V15.8333H16.6667V6.0316ZM3.75963 4.16667L10.0517 9.71833L16.2509 4.16667H3.75963Z"
        fill="black"
        fill-opacity="0.45"
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const UserLineIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path d="M3.33333 18.3333C3.33333 14.6514 6.31809 11.6667 9.99999 11.6667C13.6819 11.6667 16.6667 14.6514 16.6667 18.3333H15C15 15.5719 12.7614 13.3333 9.99999 13.3333C7.23857 13.3333 4.99999 15.5719 4.99999 18.3333H3.33333ZM9.99999 10.8333C7.23749 10.8333 4.99999 8.59584 4.99999 5.83334C4.99999 3.07084 7.23749 0.833344 9.99999 0.833344C12.7625 0.833344 15 3.07084 15 5.83334C15 8.59584 12.7625 10.8333 9.99999 10.8333ZM9.99999 9.16668C11.8417 9.16668 13.3333 7.67501 13.3333 5.83334C13.3333 3.99168 11.8417 2.50001 9.99999 2.50001C8.15833 2.50001 6.66666 3.99168 6.66666 5.83334C6.66666 7.67501 8.15833 9.16668 9.99999 9.16668Z" fill="black" fill-opacity="0.45"/>
    );
  },
  { viewBox: '0 0 20 20' }
);

export const ShieldUserIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        id="Vector"
        d="M3.15256 2.35499L10 0.833344L16.8474 2.35499C17.2287 2.43973 17.5 2.7779 17.5 3.16849V11.4908C17.5 13.1625 16.6645 14.7237 15.2735 15.651L10 19.1667L4.7265 15.651C3.33551 14.7237 2.5 13.1625 2.5 11.4908V3.16849C2.5 2.7779 2.77128 2.43973 3.15256 2.35499ZM4.16667 3.83696V11.4908C4.16667 12.6053 4.72367 13.646 5.651 14.2643L10 17.1636L14.349 14.2643C15.2763 13.646 15.8333 12.6053 15.8333 11.4908V3.83696L10 2.54067L4.16667 3.83696ZM10 9.16668C8.84942 9.16668 7.91667 8.23394 7.91667 7.08334C7.91667 5.93275 8.84942 5.00001 10 5.00001C11.1506 5.00001 12.0833 5.93275 12.0833 7.08334C12.0833 8.23394 11.1506 9.16668 10 9.16668ZM6.27288 13.3333C6.48016 11.4583 8.06977 10 10 10C11.9303 10 13.5198 11.4583 13.7271 13.3333H6.27288Z"
        fill="black"
        fill-opacity="0.45"
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const LockIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M15.8333 7.33335H16.6667C17.1269 7.33335 17.5 7.70644 17.5 8.16669V16.5C17.5 16.9603 17.1269 17.3334 16.6667 17.3334H3.33333C2.8731 17.3334 2.5 16.9603 2.5 16.5V8.16669C2.5 7.70644 2.8731 7.33335 3.33333 7.33335H4.16667V6.50002C4.16667 3.27836 6.77834 0.666687 10 0.666687C13.2217 0.666687 15.8333 3.27836 15.8333 6.50002V7.33335ZM4.16667 9.00002V15.6667H15.8333V9.00002H4.16667ZM9.16667 10.6667H10.8333V14H9.16667V10.6667ZM14.1667 7.33335V6.50002C14.1667 4.19884 12.3012 2.33335 10 2.33335C7.69882 2.33335 5.83333 4.19884 5.83333 6.50002V7.33335H14.1667Z"
        fill="black"
        fill-opacity="0.45"
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const ShareBoxIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M8.33333 2.5V4.16667H4.16667V15.8333H15.8333V11.6667H17.5V16.6667C17.5 17.1269 17.1269 17.5 16.6667 17.5H3.33333C2.8731 17.5 2.5 17.1269 2.5 16.6667V3.33333C2.5 2.8731 2.8731 2.5 3.33333 2.5H8.33333ZM14.6548 4.16667H10.8333V2.5H17.5V9.16667H15.8333V5.34517L10 11.1785L8.8215 10L14.6548 4.16667Z"
        fill={fillColor}
      />
    );
  },
  { viewBox: '0 0 24 24' }
);

export const MoreIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        id="Vector"
        d="M9.99992 2.5C9.08325 2.5 8.33325 3.25 8.33325 4.16667C8.33325 5.08333 9.08325 5.83333 9.99992 5.83333C10.9166 5.83333 11.6666 5.08333 11.6666 4.16667C11.6666 3.25 10.9166 2.5 9.99992 2.5ZM9.99992 14.1667C9.08325 14.1667 8.33325 14.9167 8.33325 15.8333C8.33325 16.75 9.08325 17.5 9.99992 17.5C10.9166 17.5 11.6666 16.75 11.6666 15.8333C11.6666 14.9167 10.9166 14.1667 9.99992 14.1667ZM9.99992 8.33333C9.08325 8.33333 8.33325 9.08333 8.33325 10C8.33325 10.9167 9.08325 11.6667 9.99992 11.6667C10.9166 11.6667 11.6666 10.9167 11.6666 10C11.6666 9.08333 10.9166 8.33333 9.99992 8.33333Z"
        fill={fillColor}
        fill-opacity="0.45"
      />
    );
  },
  { viewBox: '0 0 24 24' }
);

export const EditIcon = createSvgIcon(
  ({ fillColor = 'black' }) => {
    return (
      <path
        d="M13.9645 2.49729L12.2978 4.16396H4.16667V15.8307H15.8333V7.69949L17.5 6.03283V16.664C17.5 17.1242 17.1269 17.4973 16.6667 17.4973H3.33333C2.8731 17.4973 2.5 17.1242 2.5 16.664V3.33063C2.5 2.87039 2.8731 2.49729 3.33333 2.49729H13.9645ZM17.0711 1.74771L18.2496 2.92623L10.5892 10.5866L9.41283 10.5886L9.41075 9.40807L17.0711 1.74771Z"
        fill={fillColor}
        fill-opacity="0.45"
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const DeleteIcon = createSvgIcon(
  ({ fillColor = 'black' }) => {
    return (
      <path
        d="M5.83341 3.33332V1.66666H14.1667V3.33332H18.3334V4.99999H16.6667V17.5C16.6667 17.9602 16.2937 18.3333 15.8334 18.3333H4.16675C3.70651 18.3333 3.33341 17.9602 3.33341 17.5V4.99999H1.66675V3.33332H5.83341ZM5.00008 4.99999V16.6667H15.0001V4.99999H5.00008ZM7.50008 7.49999H9.16675V14.1667H7.50008V7.49999ZM10.8334 7.49999H12.5001V14.1667H10.8334V7.49999Z"
        fill={fillColor}
        fill-opacity="0.45"
      />
    );
  },
  { viewBox: '0 0 20 20' }
);

export const SetAsDefaultIcon = createSvgIcon(
  ({ fillColor = 'black' }) => {
    return (
    <path id="Vector" d="M10.0001 18.3337C5.39771 18.3337 1.66675 14.6027 1.66675 10.0003C1.66675 5.39795 5.39771 1.66699 10.0001 1.66699C14.6024 1.66699 18.3334 5.39795 18.3334 10.0003C18.3334 14.6027 14.6024 18.3337 10.0001 18.3337ZM10.0001 16.667C13.682 16.667 16.6667 13.6822 16.6667 10.0003C16.6667 6.31843 13.682 3.33366 10.0001 3.33366C6.31818 3.33366 3.33341 6.31843 3.33341 10.0003C3.33341 13.6822 6.31818 16.667 10.0001 16.667ZM9.16891 13.3337L5.63341 9.79816L6.81192 8.61958L9.16891 10.9767L13.883 6.26259L15.0615 7.4411L9.16891 13.3337Z" fill="black" fill-opacity="0.45"/>
    );
  },
  { viewBox: '0 0 20 20' }
);

export const ToBottomIcon = createSvgIcon(
  ({ fillColor = 'black' }) => {
    return (
    <path d="M9.99977 10.9761L14.1246 6.85132L15.3031 8.02983L9.99977 13.3332L4.69647 8.02983L5.87499 6.85132L9.99977 10.9761Z" fill="black" fill-opacity="0.45"/>
    );
  },
  { viewBox: '0 0 20 20' }
);

export const ToTopIcon = createSvgIcon(
  ({ fillColor = 'black' }) => {
    return (
    <path d="M9.99977 9.02375L5.87499 13.1486L4.69647 11.9701L9.99977 6.66675L15.3031 11.9701L14.1246 13.1486L9.99977 9.02375Z" fill="#1A5AFF"/>
    );
  },
  { viewBox: '0 0 20 20' }
);

export const CopyIcon = createSvgIcon(
  ({ fillColor = 'black' }) => {
    return (
    <path d="M5.83317 4.99999V2.49999C5.83317 2.03976 6.20627 1.66666 6.6665 1.66666H16.6665C17.1267 1.66666 17.4998 2.03976 17.4998 2.49999V14.1667C17.4998 14.6269 17.1267 15 16.6665 15H14.1665V17.4992C14.1665 17.9599 13.7916 18.3333 13.3275 18.3333H3.33888C2.87549 18.3333 2.5 17.9628 2.5 17.4992L2.50217 5.83405C2.50225 5.37341 2.8772 4.99999 3.34118 4.99999H5.83317ZM4.16868 6.66666L4.16682 16.6667H12.4998V6.66666H4.16868ZM7.49983 4.99999H14.1665V13.3333H15.8332V3.33332H7.49983V4.99999Z" fill="black" fill-opacity="0.25"/>
    );
  },
  { viewBox: '0 0 20 20' }
);

export const ETHIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <>
        <path
          d="M16.1231 7.33398L16.0039 7.72902V19.1909L16.1231 19.3069L21.5776 16.162L16.1231 7.33398Z"
          fill="#343434"
        />
        <path
          d="M16.1226 7.33398L10.668 16.162L16.1226 19.3069V13.7437V7.33398Z"
          fill="#8C8C8C"
        />
        <path
          d="M16.1219 20.3132L16.0547 20.3931V24.476L16.1219 24.6674L21.5797 17.1699L16.1219 20.3132Z"
          fill="#3C3C3B"
        />
        <path
          d="M16.1226 24.6674V20.3132L10.668 17.1699L16.1226 24.6674Z"
          fill="#8C8C8C"
        />
        <path
          d="M16.1211 19.3055L21.5756 16.1606L16.1211 13.7422V19.3055Z"
          fill="#141414"
        />
        <path
          d="M10.668 16.1606L16.1226 19.3055V13.7422L10.668 16.1606Z"
          fill="#393939"
        />
      </>
    );
  },
  { viewBox: '0 0 24 24' }
);

export const BSCIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8925 14.7246L15.9998 11.6176L19.1086 14.7259L20.9166 12.9181L15.9998 8.00195L11.0846 12.9167L12.8926 14.7245L12.8925 14.7246ZM8 16.0016L9.80806 14.1934L11.616 16.0012L9.80793 17.8091L8 16.0016ZM15.9992 20.3863L12.8919 17.2795L11.0815 19.0847L11.0841 19.0873L15.9992 24.002L20.916 19.0858L20.9169 19.0849L19.108 17.278L15.9992 20.3863ZM20.384 16.0013L22.1921 14.1934L24 16.0012L22.192 17.8091L20.384 16.0013ZM17.8341 15.9998H17.8335L17.8352 16.0007L17.8343 16.0017L15.9996 17.8362L14.1666 16.0031L14.1641 16.0004L14.1666 15.9979L14.4879 15.6766L14.6437 15.5208L15.9996 14.1654L17.8341 15.9998Z"
        fill="#F3BA2F"
      />
    );
  },
  { viewBox: '0 0 24 24' }
);

export const AVAIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7877 8.47617C16.4215 7.84388 15.839 7.84388 15.4729 8.47617L8.82423 20.1818C8.4581 20.8224 8.75766 21.3299 9.48993 21.3299H12.8268C13.5008 21.2883 14.1082 20.9305 14.4744 20.3648L18.4935 13.4013C18.7848 12.8023 18.7848 12.0952 18.4935 11.4962L17.2953 9.39132L16.7877 8.47617ZM21.2073 16.1858C20.8412 15.5535 20.2504 15.5535 19.8842 16.1858L17.5626 20.1875C17.2048 20.8198 17.5044 21.3356 18.2283 21.3356H22.8216C23.5539 21.3356 23.8535 20.8198 23.4873 20.1875L21.2073 16.1858Z"
        fill="#E84142"
      />
    );
  },
  { viewBox: '0 0 24 24' }
);

export const TRONIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.7228 13.0845C22.4369 12.8205 22.1054 12.4987 21.76 12.1633L21.76 12.1633C21.2748 11.6922 20.7621 11.1944 20.3097 10.7928L20.2638 10.7608C20.1876 10.6995 20.1016 10.6515 20.0095 10.6187L19.6603 10.5535C17.2508 10.104 9.44041 8.64667 9.27974 8.66621C9.23222 8.67287 9.1868 8.69009 9.14683 8.71663L9.10328 8.751C9.04967 8.80545 9.00895 8.87123 8.98411 8.9435L8.97266 8.97329V9.136V9.1612C9.50291 10.6377 10.8803 13.935 12.1522 16.9795L12.1522 16.9795L12.1522 16.9796L12.1522 16.9796C13.2275 19.5536 14.2273 21.9469 14.5759 22.9064L14.576 22.9068C14.6218 23.049 14.7089 23.3189 14.8715 23.3327H14.9081C14.9952 23.3327 15.3665 22.8423 15.3665 22.8423C15.3665 22.8423 22.0032 14.794 22.6747 13.937C22.7616 13.8314 22.8383 13.7178 22.9039 13.5978C22.9379 13.4068 22.8692 13.2119 22.7228 13.0845ZM17.069 14.0228L19.9015 11.6738L21.563 13.2046L17.069 14.0228ZM11.0924 9.87435L15.9692 13.871L18.9828 11.3295L11.0924 9.87435ZM16.409 14.9183L21.4003 14.1139L15.694 20.9888L16.409 14.9183ZM15.5614 14.6243L10.4303 10.2702L14.8189 20.9905L15.5614 14.6243Z"
          fill="#FC070C"
        />
      </>
    );
  },
  { viewBox: '0 0 24 24' }
);

export const ExchangeIcon = createSvgIcon(
  ({ fillColor }) => {
    return (
      <path
        d="M5.83325 17.9167C3.76219 17.9167 2.08325 16.2377 2.08325 14.1667C2.08325 12.0956 3.76219 10.4167 5.83325 10.4167C7.90432 10.4167 9.58325 12.0956 9.58325 14.1667C9.58325 16.2377 7.90432 17.9167 5.83325 17.9167ZM14.1666 9.58333C12.0955 9.58333 10.4166 7.90439 10.4166 5.83333C10.4166 3.76226 12.0955 2.08333 14.1666 2.08333C16.2377 2.08333 17.9166 3.76226 17.9166 5.83333C17.9166 7.90439 16.2377 9.58333 14.1666 9.58333ZM2.49992 6.66666C2.49992 4.36548 4.3654 2.49999 6.66658 2.49999H9.16658V4.16666H6.66658C5.28588 4.16666 4.16659 5.28595 4.16659 6.66666V9.16666H2.49992V6.66666ZM15.8333 10.8333V13.3333C15.8333 14.7141 14.714 15.8333 13.3333 15.8333H10.8333V17.5H13.3333C15.6344 17.5 17.4999 15.6345 17.4999 13.3333V10.8333H15.8333Z"
        fill="black"
      />
    );
  },
  { viewBox: '0 0 24 24' }
);

import * as React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import twitter from 'images/new/twitter-x-fill.svg'
import medium from 'images/new/medium-fill.svg'
import discord from 'images/new/discord-fill.svg'
import telegram from 'images/new/telegram-fill.svg'
import { Tooltip, Space, Flex } from 'antd';

const FloatBottomLeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const FloatBottomLeft = styled.div`
  font-size: 14px;
  color: white;
  display: flex;
  gap: 20px;
`;

const MediaLink = styled.a`
  text-decoration: none;
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  &:hover {
    color: #1A5AFF;
  }
`;

const IconMedia = styled.img`
  width: 24px;
  height: 24px;
  display: inline-block;
`

const title = (
  <Space direction="vertical" size={6}>
      <Flex gap={12} align='center'>
      <IconMedia src={telegram} />
        <MediaLink
        href="https://t.me/TrueUSD_ANN"
        target="_blank"
        >TrueUSD Channel</MediaLink>
      </Flex>
      <Flex gap={12} align='center'>
      <IconMedia src={telegram} />
        <MediaLink
        href="https://t.me/TUSDofficial_EN"
        target="_blank"
        >TrueUSD Group</MediaLink>
      </Flex>
    </Space>
)

function FloatingShareButtons() {
  // Don't do bottom right because that's where we show floating help button in prod
  return (
    <FloatBottomLeftContainer>
    <FloatBottomLeft>
      <Helmet>
        <meta name="title" content="TrueUSD" />
        <meta name="description" content="The easiest way to move money into and out of crypto" />
      </Helmet>
      <MediaLink
        href="https://twitter.com/tusdio"
        target="_blank"
      >
        <IconMedia src={twitter} />
      </MediaLink>
      <MediaLink
        href="https://discord.gg/DU4CXtcsSZ"
        target="_blank"
      >
        <IconMedia src={discord} />
      </MediaLink>
      <MediaLink
        href="https://medium.com/@trueusd"
        target="_blank"
      >
        <IconMedia src={medium} />
      </MediaLink>
      <Tooltip
      overlayStyle={{ width: '280px' }}
      color="#fff"
      title={title}
      placement="top"
      >
        <IconMedia src={telegram} />
      </Tooltip>
    </FloatBottomLeft >
    </FloatBottomLeftContainer>
  );
}

export { FloatingShareButtons };

function getCookie(cname) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let cookie of ca) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}

function deleteCookie(name) {
  // need to fix domain
  const cookieCmd = `${name}=; max-age=0`;
  document.cookie = cookieCmd;
}

export { getCookie, deleteCookie };

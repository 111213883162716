import styled from 'styled-components';

const ContentSection = styled.div`
  color: ${({ theme }) => theme. colors.neutral.darker}
  max-width: 768px;
  margin: 48px auto;

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  ${({ centered }) => centered && 'text-align: center;'}
`;

export { ContentSection };

import { colors } from './colors';

export const theme = {
  colors,
  global: {
  },
  header: {
    h1: {
      fontSize: '40px',
      lineHeight: '48px',
    },
    h2: {
      fontSize: '30px',
      lineHeight: '36px',
    },
    h3: {
      fontSize: '24px',
      lineHeight: '36px',
    },
  },
  text: {
    accent: colors.red.default,
  },
  button: {
    colors: {
      disabled: colors.neutral.light,
      primary: {
        default: colors.red.default,
        light: colors.red.light,
        dark: colors.red.dark,
      },
      secondary: {
        default: colors.blue.default,
        light: colors.blue.light,
        dark: colors.blue.dark,
      },
      simple: {
        default: colors.blue.default,
        light: colors.blue.light,
        dark: colors.blue.dark,
      },
    },
  },
  notice: {
    default: {
      background: {
        color: '#FFFBEC',
        border: `1px solid ${colors.neutral.lighter}`,
      },
      label: {
        color: colors.neutral.darker,
        fontWeight: 'normal',
      },
      description: {
        color: colors.neutral.darker,
      },
    },
    defaultBoldLabel: {
      background: {
        color: '#FFFBEC',
        border: `1px solid ${colors.neutral.lighter}`,
      },
      label: {
        color: colors.blue.darker,
        fontWeight: 'bold',
      },
      description: {
        color: colors.neutral.darker,
      },
    },
    banner: {
      background: {
        color: colors.blue.lightest,
        border: `1px solid ${colors.neutral.lighter}`,
      },
      label: {
        color: colors.blue.darker,
        fontWeight: 'bold',
      },
      description: {
        color: colors.neutral.darker,
      },
    },
  },
  input: {
    label: {
      default: colors.neutral.dark,
    },
    border: {
      default: colors.neutral.lighter,
      dark: colors.neutral.dark,
    },
    borderRadius: '1px',
    selectButton: {
      light: 'transparent',
    },
    selectIcon: {
      default: colors.neutral.lighter,
      dark: colors.neutral.dark,
    },
  },
  docUpload: {
    notes: {
      default: colors.neutral.default,
    },
    dropzone: {
      background: {
        default: '#F6F6F6',
      },
      border: {
        default: colors.blue.darker,
      },
      action: {
        default: colors.red.default,
        dark: colors.red.dark,
      },
    },
  },
  infoBox: {
    border: {
      default: colors.neutral.lighter,
    },
    title: {
      color: colors.blue.default,
    },
    label: {
      color: colors.neutral.dark,
    },
    item: {
      color: colors.blue.default,
      border: {
        default: colors.neutral.lighter,
      },
    },
  },
};

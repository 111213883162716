import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import profileIconImgUrl from 'images/new/account-circle-fill.svg';
import { withStore } from 'lib/store';
import { Button, Dropdown, Space } from 'antd';
import { BankIcon, AccountIcon, UserIcon, WalletIcon, LogoutIcon } from 'components/AntdComponets/icons';

const TopBarProfileContainer = styled.div`
  cursor: pointer;
  position: relative;
`;

// Main container for Icon + Person name
const Profile = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const ProfileIcon = styled.img`
  margin-right: 10px;
  width: 24px;
  height: 24px;
  display: block;
`;

// Dropdown Floating Menu
const ProfileMenu = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: 120px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 5px 12px 0 rgb(0, 0, 0, 0.15);
  z-index: 100;
`;

const ProfileOption = styled(Link)`
  display: block;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  box-sizing: border-box;
  &:hover {
    color: #1A5AFF;
  }
`;

const items = [
  {
    key: '1',
    label: (
      <ProfileOption to="/account/info">Account Info</ProfileOption>
    ),
    icon: <AccountIcon style={{ fontSize: '20px' }} />,
  },
  {
    key: '2',
    label: (
      <ProfileOption to="/account/verification">Verification</ProfileOption>
    ),
    icon: <UserIcon style={{ fontSize: '20px' }} />,
  },
  {
    key: '3',
    label: (
      <ProfileOption to="/account/bank-accounts">Bank Accounts</ProfileOption>
    ),
    icon: <BankIcon style={{ fontSize: '20px' }} />,
  },
  {
    key: '4',
    label: (
      <ProfileOption to="/account/deposit-addresses">Deposit Addresses</ProfileOption>
    ),
    icon: <WalletIcon style={{ fontSize: '20px' }} />,
  },
  {
    key: '5',
    label: (
      <ProfileOption to="/logout">Sign out</ProfileOption>
    ),
    icon: <LogoutIcon style={{ fontSize: '20px' }} />,
  },
];

function TopBarProfileComponent(props) {
  const { person, organization, history, store } = props;
  // const profileMenuRef = useRef(null);

  const onClick = ({ key }) => {
    // message.info(`Click on item ${key}`);
  };

  // const [menuVisible, setMenuVisible] = useState(false);

  const isDisabeldMintAndRedeem = store.isDisabeldMintAndRedeem

  // const onClickProfile = () => {
  //   setMenuVisible(!menuVisible);
  // };

  // useEffect(() => {
  //   // Close the menu when clicking outside it
  //   if (menuVisible) {
  //     const handleClick = (evt) => {
  //       if (profileMenuRef.current && !profileMenuRef.current.contains(evt.target)) {
  //         setMenuVisible(false);
  //       }
  //     };

  //     document.addEventListener('click', handleClick);
  //     return () => document.removeEventListener('click', handleClick);
  //   }
  // }, [menuVisible]);

  let fullName;
  if (organization) {
    fullName = organization.name;
  } else if (person) {
    fullName = `${person.firstName} ${person.lastName}`;
  }

  return (
    <TopBarProfileContainer >
      <Dropdown
      menu={{
        items,
        onClick
      }}
      overlayStyle={
        {width: '240px'}
      }
      >
      <Profile>
        <ProfileIcon src={profileIconImgUrl} />
        {fullName}
      </Profile>
      </Dropdown>

      {/* {menuVisible &&
        <ProfileMenu ref={profileMenuRef}>
          {!isDisabeldMintAndRedeem && <ProfileOption to="/account/bank-accounts">Account</ProfileOption>}
          <ProfileOption to="/logout">Logout</ProfileOption>
        </ProfileMenu>
      } */}

    </TopBarProfileContainer>
  );
}

const TopBarProfile = withStore(TopBarProfileComponent)

export { TopBarProfile };

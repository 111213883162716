import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { ApolloError } from 'apollo-client';
import { getPortalRedirectUrl, redirectToLogin } from './routing';
import { isAuthenticated } from 'lib/auth';

function UnexpectedError() {
  const supportEmail = 'support@trueusd.com';
  return (
    <div style={{ textAlign: 'center' }}>
      <h1> Unexpected Error</h1>
      <p>
        Our engineers have been notified. We'll fix it soon.
        <br />
        Need immediate help? Contact <a href={'mailto:' + supportEmail}>support</a>.
      </p>
    </div>
  );
}

export function noRegisteredCustomerError() {
  const environmentLinks = getPortalRedirectUrl();
  return (
    <div style={{ textAlign: 'center' }}>
      <h1> This user is not registered as a customer </h1>
      <p>
        Please switch to <a href={environmentLinks[0]}>internal</a>/<a href={environmentLinks[1]}>partner</a> portal.
      </p>
    </div>
  );
}

function isUnauthenticated(error) {
  return error &&
    error.graphQLErrors &&
    error.graphQLErrors.find((err) => err.extensions.code === 'UNAUTHENTICATED');
}

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    // If user is logged out, don't report the error
    if (isUnauthenticated(error) || !isAuthenticated()) {
      return;
    }
    Sentry.captureException(error);
  }

  render() {
    const { error } = this.state;
    if (error) {
      if (error instanceof ApolloError || !isAuthenticated()) {
        // TODO: consider using React context for things that don't qualify as real
        // errors such as not authenticated to avoid polluting Sentry logs
        if (isUnauthenticated(error) || !isAuthenticated()) {
          redirectToLogin();
          return null;
        }
      }

      return (
        <UnexpectedError />
      );
    }

    return this.props.children;
  }
}

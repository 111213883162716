import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      common: {
        accountName: 'Account Name',
        bankAccount: 'Bank Account',
        cancel: 'Cancel',
        continue: 'Continue',
        disclaimer: 'Disclaimer',
        done: 'Done',
        memo: 'Memo',
        overview: 'Overview',
        redemptionAddress: 'Redemption Address',
        return: 'Return',
        routingNumber: 'RoutingNumber',
        understandAgree: 'I Understand & Agree',
        warn: 'Warning',
        confirm: 'Confirm',
      },
      form: {
        labels: {
          firstName: 'Legal First Name (Given Name)',
          middleName: 'Legal Middle Name',
          lastName: 'Legal Last Name (Surname)',
          legalName: 'Legal Name',
          socialSecurityNumber: 'Social Security Number',
          govtIdNumber: 'Government Issued ID Number',
          country: 'Country',
          citizenshipCountry: 'Country of Citizenship',
          dateOfBirth: 'Date of Birth',
          residenceCountry: 'Country of Residence',
          stateOrProvince: 'State / Province',
          locality: 'City',
          postalCode: 'Zip / Postal Code',
          address: 'Address',
          unitIfApplicable: 'Unit or Floor (if applicable)',
          unit: 'Unit or Floor',
          occupation: 'Occupation',
          occupationDetails: 'Occupation Details',
          accountPurpose: 'Purpose for Opening Account',
          accountPurposeDetails: 'Purpose for Opening Account Details',
          amvAmount: 'Anticipated Monthly Volume',
          selectDocumentType: 'Select document type',
          governmentIdCountry: 'Government ID Country',
          walletAddress: 'Your Wallet Address',
          confirmWalletAddress: 'Confirm Your Wallet Address',
          walletNickname: 'Wallet Nickname',
          ownAddress: 'I own and control this wallet address.',
          exchange: 'Your Exchange',
          ownExchangeAddress: 'This exchange wallet address belongs to me.',
          memo: 'Memo',
          ownBinanceAddress: 'I certify that the information above pertains to a wallet address or exchange account owned and controlled by me.',
          ownAvalancheAddress: 'I certify that the information above pertains to a wallet address or exchange account owned and controlled by me.',
          ownBscAddress: 'I certify that the information above pertains to a wallet address or exchange account owned and controlled by me.',
          ownTronAddress: 'I certify that the information above pertains to a wallet address or exchange account owned and controlled by me.',
          personalInformation: 'Personal Information',
          nameOnAccount: 'Name on Account',
          bankInformation: 'Bank Information',
          bankName: 'Bank Name',
          accountNumber: 'Account Number / IBAN',
          bankRoutingNumber: 'Bank Routing Number',
          swiftCode: 'Swift Code',
          bankAddress: 'Bank Address',
          imBankName: 'Correspondent / Intermediary Bank Name',
          imBankSwiftCode: 'Correspondent / Intermediary Bank Swift Code',
          imBankAddress: 'Correspondent / Intermediary Bank Address',
          imBankCity: 'Correspondent / Intermediary Bank City',
          imBankState: 'Correspondent / Intermediary Bank State / Province',
          imBankZip: 'Correspondent / Intermediary Bank Zip / Postal Code',
          imBankCountry: 'Correspondent / Intermediary Bank Country',
          notes: 'Notes',
        },
      },
      notifications: {
        new: 'In order to mint or redeem, you must first <1>verify your account</1>. Click <3>here</3> to proceed.',
        newTruefi: 'Welcome back! Please <1>verify your account</1> to continue.',
        newWatr: 'You must first <1>complete your application</1>. Click <3>here</3> to proceed.',
        newRedeemOnly: 'In order to redeem, you must first <1>verify your identity</1>.',
        approved: 'Thank you for updating your account. We will process your application within 1-3 business day.',
        rejected: 'We need more information about your account. Please click <1>here</1> to update your information',
        resubmission: 'To improve our service, the Identity verification (KYC) system has been upgraded. Please click <1>here</1> to submit the supplementary information within {{gracePeriod}} days to upgrade your account, or the Mint and Redemption functions will be unavailable.',
        rejectedUSUser: 'Due to policy restrictions, we regret to inform you that we no longer provide services to the united states. If you have any questions, please contact <1>support@trueusd.com</1>. We apologize for the inconvenience caused.',
        disablePrimeTrust: 'We regret to inform you that we no longer support Prime Trust as our banking partner. Please contact <1>support@trueusd.com</1> to switch to a supported bank. We apologize for the inconvenience caused.',
        newUser: "Delighted to welcome you aboard TrueUSD. Don't hesitate to get in touch with <1>support@trueusd.com</1> for assistance with your first Mint/Redemption.",
        pauseIndividualRegister: "To provide better user experience, we're currently going through an upgrade on our Identity Verification (KYC) system. Please rest assured that once the upgrade is completed, we will email and notify you as soon as we can. Thank you for your support."
      },
      purchase: {
        buttonLabel: 'Mint {{product}}',
        header: 'How to mint {{productName}}',
        instructions: '<p>1. Enter your wallet address.</p><p>2. Wire funds from your bank account, using the wiring instructions provided.</p><p>3. {{productName}} will be sent to your wallet address.</p>',
      },
      redeem: {
        buttonLabel: 'Redeem {{product}}',
        header: 'How to redeem {{productName}}',
        dashboardInstructions: '<p>1. Add the bank account where you wish to receive funds.</p><p> 2. Send {{productName}} to your redemption address, using the provided instructions.</p><p>3. {{productCurrency}} will be deposited to your bank account.</p>',
        overview: 'You can redeem {{productName}} by sending the amount you would like to redeem to a specific redemption address that we will provide on the next page. This redemption address remains the same each time you want to redeem your {{productName}}. After the on-chain transaction is completed successfully, you will be wired {{currency}} to the bank account provided.',
        redemptionAddress: 'Below please find your unique redemption address, which may be used to redeem your {{productName}}.\nIn order to redeem your {{productName}}, you will first need to request the amount of {{productName}} you wish to redeem and it will then be sent to your unique redemption address. Once the on-chain transaction is successfully completed, you will be wired {{currency}} to the bank account provided.\nPlease note that your unique redemption address will remain the same for each redemption requested.',
        toReceive: 'To receive your redemption address, you must read and agree to the terms & conditions below.',
        getAddress: 'Get Redemption Address',
        yourAddress: 'Your redemption address',
        minimumSize: 'Note: the minimum redemption size is {{minAmountString}} {{product}}',
        willNotBeProcessed: "The test redemption can be 1,000 {{product}}. Any redemptions of less than {{minAmountString}} {{product}} for further redemption requests will not be processed. The smart contract will automatically return {{product}} to the wallet you sent funds from.",
        nativeToken: 'Note: ONLY {{chain}} native {{productName}} tokens are supported for redemptions, pegged {{productName}} tokens sent to the {{chain}} redemption address will be irrevocably LOST.',
        nativeTokenAddress: 'The {{chain}} native {{productName}} token contract address is: <1>{{productAddress}}</1>',
        // bnbNativeTokenAddress: 'The {{chain}} native {{productName}} token is: <1>{{productAddress}}</1>',
        addressNotReady: 'Your redemption address will be ready soon. Please check back later. To activate the redemption service on {{chain}}, please ensure that a {{chain}} wallet has been added.',
        updateTips: 'Notice: Your redemption address has been updated. Please use the latest redemption address to redeem.',
      },
      bankAccount: {
        add: 'Add bank account',
        confirmTitle: 'Confirm the bank account to redeem {{productName}}',
        confirmBankName: 'Bank Name: {{bankName}}',
        confirmBankDesc: 'Your funds will be processed through {{bankName}}, please contact <1>support@trueusd.com</1> before you redeem your {{productName}}.'
      },
      blockchainAddress: {
        instructions: 'For minting {{productName}}, you will need to set up a wallet address.',
        header: 'Wallet for {{productName}}',
        chooseTypeTitle: 'Wallet address setup',
        chooseTypeERC20: 'I\'m using my own ERC-20 wallet.',
        chooseTypeExchange: 'I\'m using an exchange wallet.',
        chooseTypeBinance: 'I\'m using my Binance wallet.',
        // chooseTypeBinanceChain: 'I want to set up a Binance Chain wallet.',
        chooseTypeAvalanche: 'I\'m using my Avalanche wallet.',
        chooseTypeAvalancheChain: 'I want to set up an Avalanche Chain wallet.',
        chooseTypeTron: 'I\'m using my TRON wallet.',
        chooseTypeTronChain: 'I want to set up a TRON Chain wallet.',
        chooseTypeBsc: 'I\'m using my BSC wallet.',
        chooseTypeBscChain: 'I want to set up a BSC Chain wallet.',
        confirmTitle: 'Confirm your wallet address',
        confirmDescription: 'We will be depositing {{productName}} to the below address.',
        confirmDisclaimer: 'By clicking “{{label}}” below, I certify the {{type}} wallet address information I am providing is correct. I understand it is my responsibility alone to verify I have entered my correct {{type}} address. I agree that TrueUSD and all affiliated entities are not liable for the accuracy of the information I am providing and shall not be liable for any error or omissions in the information I am providing.',
        confirmChange: 'Change Wallet Address',
        createTitle: 'Please enter your {{type}} wallet address',
        createDescription: 'Please enter your {{type}} wallet address for {{productName}}. We will deposit {{productName}} to this address.',
        disclaimerTitle: 'Disclaimer',
        createDisclaimer: 'I certify the wallet address information I am providing is correct. I understand it is my responsibility alone to verify I have entered my correct wallet address. I agree that TrueUSD and all affiliated entities are not liable for the accuracy of the information I am providing and shall not be liable for any error or omissions in the information I am providing.',
        setupTitle: 'Use an existing wallet address for {{productName}}',
        // whatBinanceChain: 'What is Binance Chain?',
        whatAvalancheChain: 'What is Avalanche Chain?',
        whatBscChain: 'What is BSC Chain?',
        whatTronChain: 'What is TRON Chain?',
        addNew: 'Add New Wallet Address',
        addressAdded: 'Your address has been added.',
      },
      legal: {
        redeemTermsAndConditions: 'TERMS & CONDITIONS <br /><br />Only send TrueUSD tokens to your Redemption Address.<br/> <br/> I acknowledge that TrueUSD, LLC are not responsible for any non-TUSD token that is sent to your Redemption Address. Your Redemption Address is randomly generated by our system and we do not control the private keys to your Redemption Address.Non-TrueUSD tokens sent to your Redemption Address may be irretrievable and irrevocably lost. TrueUSD is not responsible for any TrueUSD or non-TrueUSD tokens you send to a wrong address.  <br/> <br/> I agree to not disclose or transmit my Redemption Address or Memo Address (or "burn addresses") to any third party.  <br/> <br/> I agree to never request any third party to transfer or cause a third party to transfer TrueUSD tokens to my burn addresses.  <br/> <br/> I agree to immediately provide TrueUSD with written notice to legal@trueusd.com if any third party transfers TrueUSD tokens to my burn addresses. I understand TrueUSD shall send me an email confirmation to my email address on file with TrueUSD.',
        readAndAgreeToTermsAndConditions: 'I\'ve read and agree to the Terms and Conditions',
      },
      notice: {
        blockedInUS: 'Thank you for your continued interest in TUSD.  At this time we are evaluating new regulatory guidance from your state. As we assess the new guidance, TrueUSD is temporarily pausing mints and redemptions for residents of your state. We are working to assess this new guidance as soon as possible in order to continue offering the most trusted and compliant stablecoin products to our customers.',
      },
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    resources,
    detection: {
      order: ['navigator'],
    },
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

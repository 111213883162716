import React, { lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import { ProtectedRoute } from 'components/routing/ProtectedRoute';
const Home = lazy(() => import('pages/Home'));
const LogOut = lazy(() => import('pages/LogOut'));
const TermsOfUse = lazy(() => import('pages/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const NotAuthorizedPage = lazy(() => import('pages/NotAuthorizedPage'));
const SignUpOrSignIn = lazy(() => import('pages/SignUpOrSignIn'));
const DashboardHome = lazy(() => import('pages/dashboard/DashboardHome'));
const ChooseAccountType = lazy(() => import('pages/onboarding/ChooseAccountType'));
const ChooseAreaOfOperation = lazy(() => import('pages/onboarding/ChooseAreaOfOperation'));
const ChooseBlockchainAccountType = lazy(() => import('pages/dashboard/ChooseBlockchainAccountType'));
const ConfirmBankAccount = lazy(() => import('pages/dashboard/ConfirmBankAccount'));
const SetupBankAccount = lazy(() => import('pages/dashboard/SetupBankAccount'));
const ConfirmDepositAddress = lazy(() => import('pages/dashboard/ConfirmDepositAddress'));
const CreateBankAccount = lazy(() => import('pages/dashboard/CreateBankAccount'));
const CreateBlockchainAccount = lazy(() => import('pages/dashboard/CreateBlockchainAccount'));
const SetupBlockchainAccount = lazy(() => import('pages/dashboard/SetupBlockchainAccount'));
const CreateBlockchainAccountSuccess = lazy(() => import('pages/dashboard/CreateBlockchainAccountSuccess'));
const CreateIndividualAccount = lazy(() => import('pages/onboarding/CreateIndividualAccount'));
const CreateOrganizationAccount = lazy(() => import('pages/onboarding/CreateOrganizationAccount'));
const CreateOrganizationAccountEmailSuccess = lazy(() => import('pages/onboarding/CreateOrganizationAccountEmailSuccess'));
const EnterCompanyInformation = lazy(() => import('pages/onboarding/EnterCompanyInformation'));
const IndividualApplicantInformation = lazy(() => import('pages/onboarding/IndividualApplicantInformation'));
const IndividualPrimaryIdentification = lazy(() => import('pages/onboarding/IndividualUploadPrimaryDocs'));
const IndividualSecondaryIdentification = lazy(() => import('pages/onboarding/IndividualUploadSecondaryDocs'));
const IndividualReviewInformation = lazy(() => import('pages/onboarding/IndividualReviewInformation'));
const OrganizationUploadAccountHolderDocs = lazy(() => import('pages/onboarding/OrganizationUploadAccountHolderDocs'));
const OrganizationUploadSignatoryDocs = lazy(() => import('pages/onboarding/OrganizationUploadSignatoryDocs'));
const OrganizationUploadIndividualOwnerDocs = lazy(() => import('pages/onboarding/OrganizationUploadIndividualOwnerDocs'));
const OrganizationUploadEntityOwnerDocs = lazy(() => import('pages/onboarding/OrganizationUploadEntityOwnerDocs'));
const OrganizationVerifyKyc = lazy(() => import('./onboarding/OrganizationVerifyKyc'));
const ApplicationSubmitted = lazy(() => import('pages/onboarding/ApplicationSubmitted'));
const OptInForUpdates = lazy(() => import('pages/onboarding/OptInForUpdates'));
const PurchaseInstructions = lazy(() => import('pages/dashboard/PurchaseInstructions'));
const RedemptionInstructions = lazy(() => import('pages/dashboard/RedemptionInstructions'));
const RedemptionReviewDetails = lazy(() => import('pages/dashboard/RedemptionReviewDetails'));
const VerifyBeforeRedeem = lazy(() => import('pages/dashboard/VerifyBeforeRedeem'));
const Settings = lazy(() => import('pages/dashboard/Settings'));
const Transactions = lazy(() => import('pages/dashboard/Transactions'));
const VerifyEmail = lazy(() => import('pages/onboarding/VerifyEmail'));
const VerifyAccount = lazy(() => import('pages/onboarding/VerifyAccount'));
const MFAInfo = lazy(() => import('pages/onboarding/MFAInfo'));
const MFASetup = lazy(() => import('pages/onboarding/MFASetup'));
const RedemptionAddress = lazy(() => import('pages/dashboard/RedemptionAddress'));
const SignatureAndSilvergateFaq = lazy(() => import('pages/dashboard/SignatureAndSilvergateFaq'));
const ReviewWiringInstructions = lazy(() => import('pages/dashboard/PurchaseInstructions/ReviewWiringInstructions'));
const ResetPassword = lazy(() => import('pages/dashboard/Settings/ResetPassword'));

const Routes = () => (
  <>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/logout" component={LogOut} />
      <Route exact path="/terms-of-use" component={TermsOfUse} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/not-authorized" component={NotAuthorizedPage} />
      <ProtectedRoute exact path="/signup-or-signin" component={SignUpOrSignIn} loginDetection />
      <ProtectedRoute exact path="/choose-account-type" component={ChooseAccountType} loginDetection />
      <Route exact path="/opt-in-for-updates" component={OptInForUpdates} />
      <Route exact path="/individual/choose-area-of-operation" component={ChooseAreaOfOperation} />
      <Route exact path="/individual/create-account" component={CreateIndividualAccount} />
      <Route exact path="/institution/create-account" component={CreateOrganizationAccount} />
      {/* <Route exact path="/institution/create-account/authorized-user" component={CreateOrganizationAccountEmail} /> */}
      <Route exact path="/institution/onboarding-email-success" component={CreateOrganizationAccountEmailSuccess} />
      <Route exact path="/institution/enter-company-information" component={EnterCompanyInformation} />
      <Route
        exact
        path="/verify-email"
        component={VerifyEmail}
      />
      <Route
        exact
        path="/mfa-info"
        component={MFAInfo}
      />
      <ProtectedRoute
        exact
        path="/mfa-setup"
        component={MFASetup}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/verify-account"
        component={VerifyAccount}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/individual/verify/applicant-information"
        component={IndividualApplicantInformation}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/individual/verify/primary-identification"
        component={IndividualPrimaryIdentification}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/individual/verify/proof-of-address"
        component={IndividualSecondaryIdentification}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/individual/review-information"
        component={IndividualReviewInformation}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/organization/verify/account-holder"
        component={OrganizationUploadAccountHolderDocs}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/organization/verify/individual-owners"
        component={OrganizationUploadIndividualOwnerDocs}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/organization/verify/entity-owners"
        component={OrganizationUploadEntityOwnerDocs}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/organization/verify/signatory"
        component={OrganizationUploadSignatoryDocs}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/organization/verify/kyc"
        component={OrganizationVerifyKyc}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/application-submitted"
        component={ApplicationSubmitted}
        emailVerified
      />

      <ProtectedRoute
        exact
        path="/redeem/instructions"
        component={RedemptionInstructions}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/redeem/your-redemption-address"
        component={RedemptionAddress}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/redeem/review-details"
        component={RedemptionReviewDetails}
        canTransact
      />
      <ProtectedRoute
        exact
        path="/dashboard"
        component={DashboardHome}
        emailVerified
      />
      <ProtectedRoute
        path="/transactions"
        component={Transactions}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/dashboard/setup-wallet-address"
        component={SetupBlockchainAccount}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/dashboard/choose-wallet-address-type"
        component={ChooseBlockchainAccountType}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/dashboard/add-wallet-address"
        component={CreateBlockchainAccount}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/dashboard/wallet-address-create-success"
        component={CreateBlockchainAccountSuccess}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/dashboard/purchase/instructions"
        component={PurchaseInstructions}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/dashboard/confirm-wallet-address"
        component={ConfirmDepositAddress}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/dashboard/verify-before-redeem"
        component={VerifyBeforeRedeem}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/dashboard/setup-bank-account"
        component={SetupBankAccount}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/dashboard/add-bank-account"
        component={CreateBankAccount}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/dashboard/confirm-bank-account"
        component={ConfirmBankAccount}
        emailVerified
      />
      <ProtectedRoute
        exact
        path="/dashboard/signature-silvergate-faq"
        component={SignatureAndSilvergateFaq}
        emailVerified
      />
       <ProtectedRoute
        exact
        path="/account/reset-password"
        component={ResetPassword}
        emailVerified
      />
      <ProtectedRoute
        path="/account"
        component={Settings}
        emailVerified
      />
      {process.env.REACT_APP_ENV !== 'production' &&
        <Route
          path="/review-wiring-instructions"
          component={ReviewWiringInstructions}
        />
      }
      <Redirect to="/" />
    </Switch>
  </>
);

export { Routes };

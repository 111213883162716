import * as Sentry from '@sentry/browser';
import { SENTRY_DSN } from 'lib/constants';

// Note, only call this once on page load
function startTrackingErrors() {
  // Start logging
  if (SENTRY_DSN) {
    Sentry.init({ dsn: SENTRY_DSN, environment: process.env.NODE_ENV });
  }
}

function attachUserEmailToErrors(email) {
  // Send up user email with each error we send to Sentry
  Sentry.configureScope((scope) =>
    scope.setUser({ email }),
  );
}

export { startTrackingErrors, attachUserEmailToErrors };

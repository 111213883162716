const colors = {
  white: '#ffffff',
  black: '#000000',
  red: {
    default: '#db4254',
    light: '#ef5668',
    dark: '#c02a3a',
  },
  blue: {
    default: '#6DBADB',
    lightest: '#EBECFF',
    lighter: '#A8D4E9',
    light: '#99c4dd',
    dark: '#286181',
    darker: '#212959',
  },
  neutral: {
    default: '#aaaaaa',
    lighter: '#d9d9d9',
    light: '#d7d7d7',
    dark: '#8B8EA5',
    darker: '#646464',
  },
  primary: {
    default: '#80b6d5',
    light: '#99c4dd',
    dark: '#4c98c4',
  },
  background: '#ffffff',
  success: '#80b6d5',
};

export const watrTheme = {
  colors,
  global: {
  },
  header: {
    h1: {
      fontSize: '45px',
      fontWeight: 300,
      lineHeight: '48px',
    },
    h2: {
      fontSize: '30px',
      lineHeight: '36px',
    },
    h3: {
      fontSize: '24px',
      lineHeight: '36px',
    },
  },
  text: {
    accent: colors.blue.default,
  },
  button: {
    colors: {
      disabled: colors.neutral.light,
      primary: {
        default: colors.primary.default,
        light: colors.primary.light,
        dark: colors.primary.dark,
      },
      secondary: {
        default: colors.red.default,
        light: colors.red.light,
        dark: colors.red.dark,
      },
      simple: {
        default: colors.primary.default,
        light: colors.primary.light,
        dark: colors.primary.dark,
      },
    },
  },
  notice: {
    default: {
      background: {
        color: '#A7D4E9',
        border: `1px solid ${colors.neutral.lighter}`,
      },
      label: {
        color: colors.blue.dark,
        fontWeight: 'normal',
      },
      description: {
        color: colors.blue.dark,
      },
    },
    defaultBoldLabel: {
      background: {
        color: '#A7D4E9',
        border: `1px solid ${colors.neutral.lighter}`,
      },
      label: {
        color: colors.blue.darker,
        fontWeight: 'bold',
      },
      description: {
        color: colors.blue.dark,
      },
    },
    banner: {
      background: {
        color: colors.blue.lightest,
        border: `1px solid ${colors.neutral.lighter}`,
      },
      label: {
        color: colors.blue.darker,
        fontWeight: 'bold',
      },
      description: {
        color: colors.blue.dark,
      },
    },
  },
  input: {
    label: {
      default: '#7B9CAF',
    },
    border: {
      default: colors.blue.lighter,
      dark: colors.blue.dark,
    },
    borderRadius: '13px',
    selectButton: {
      light: colors.blue.lighter,
    },
    selectIcon: {
      default: colors.blue.lighter,
      dark: colors.white,
    },
  },
  signup: {
    title: {
      marginTop: '90px',
    },
  },
  docUpload: {
    notes: {
      default: '#7B9CAF',
    },
    dropzone: {
      background: {
        default: '#e2eff6',
      },
      border: {
        default: colors.blue.lighter,
      },
      action: {
        default: colors.blue.default,
        dark: colors.blue.dark,
      },
    },
  },
  infoBox: {
    border: {
      default: colors.blue.lighter,
    },
    title: {
      color: colors.blue.dark,
    },
    label: {
      color: '#7B9CAF',
    },
    item: {
      color: colors.black,
      border: {
        default: 'transparent',
      },
    },
  },
};

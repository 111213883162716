export const BENEFICIARIES = {
  PRIME_TRUST: 'Prime Trust LLC',
  ALLIANCE_TRUST_COMPANY: 'Alliance Trust Company, LLC',
  LEGACY_TRUST_COMPANY: 'Legacy Trust Company',
  SILVERGATE_BANK: 'Silvergate Bank',
  SIGNATURE_BANK: 'Signature Bank',
  FIRST_DIGITAL_TRUST: '1st Digital Trust',
  CUB: 'Capital Union Bank',
  FlowBank: 'FlowBank',
  Deltec: 'Deltec'
};

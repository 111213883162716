import { LOGIN_REDIRECT_URL, ADMIN_URL } from 'lib/constants';

function getLocationState(propLocation, field, defaultValue = null) {
  // helper to process react-router location
  let value;
  if (propLocation.state) {
    value = propLocation.state[field];
  }
  return value || defaultValue;
}

function getPortalRedirectUrl() {
  if (process.env.REACT_APP_ENV === 'development') {
    return ['http://val.localdev:8005/internal', 'http://val.localdev:8005/partner'];
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return ['https://internal.trustdev.network/support', 'https://partner.trustdev.network/internal'];
  }

  return ['https://internal.tusd.io/support/', 'https://partner.tusd.io/internal'];
}

function redirectToLogin(nextUrl = null) {
  // add next behavior later..
  let redirectUrl = LOGIN_REDIRECT_URL;
  if (nextUrl) {
    const nextUrlEncoded = encodeURIComponent(nextUrl);
    redirectUrl = `${LOGIN_REDIRECT_URL}?next=${nextUrlEncoded}`;
  } else {
    redirectUrl = LOGIN_REDIRECT_URL;
  }

  window.location.assign(redirectUrl);
}

function redirectToAdmin() {
  window.location.assign(ADMIN_URL);
}

export {
  getLocationState, getPortalRedirectUrl, redirectToLogin, redirectToAdmin,
};

import { getCookie } from 'lib/cookie';
import { LOGIN_REDIRECT_URL } from 'lib/constants';

const SESSION_EXPIRES_AT_COOKIE = 'session_expires_at';

function isAuthenticated() {
  // just gives an approximate idea about login status
  const sessionExpiresAtStr = getCookie(SESSION_EXPIRES_AT_COOKIE);

  if (!sessionExpiresAtStr) {
    return false;
  }

  const sessionExpiresAt = new Date(sessionExpiresAtStr);
  const authenticated = (new Date()) < sessionExpiresAt;
  return authenticated;
}

function logOut() {
  const loginRedirectUrl = LOGIN_REDIRECT_URL;
  const authWebLogoutUrl = `${loginRedirectUrl}/logout`;
  window.location.assign(authWebLogoutUrl);
};

export { isAuthenticated, logOut };

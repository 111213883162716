import * as React from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import logoIconUrl from 'images/new/tusd-logo-app.svg'
import { TopBarProfile } from './TopBarProfile';
import { customerState, applicationState } from 'data/state';

let TopBarContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  color: rgba(0, 0, 0, 0.80);
  font-size: 16px;
  font-weight: 500;
  line-height: 40px
  height: 64px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,.04);
`;

let TopBarLogoLink = styled.a`
  display: flex;
  height: 40px;
  color: #000;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 16px;

  span {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
  }
`

let TopBarLogo = styled.img`
  width: 40px;
  height: 40px;
`;

const TopBarNav = styled.div`
  display: flex;
  margin-left: 40px;
  margin-right: 40px;
`;

let TopBarNavLink = styled(NavLink)`
  color: rgba(0, 0, 0, 0.80);
  text-decoration: none;
  &:hover {
    color: #1A5AFF;
  }
`;

function displayTopNav() {
  return !!customerState.customer;
  // return window.location.pathname.indexOf('/dashboard') >= 0 ||
  //   window.location.pathname.indexOf('/transactions') >= 0 ||
  //   window.location.pathname.indexOf('/account') >= 0;
}

function TopBar(props) {
  const person = customerState.person;
  const organization = customerState.organization;

  return (
    <TopBarContainer>
      <TopBarLogoLink href="/">
        <TopBarLogo src={logoIconUrl} />
        <span>TrueUSD</span>
      </TopBarLogoLink>
      {displayTopNav() &&
        <TopBarNav>
          <TopBarNavLink
            to={'/dashboard'}
            activeStyle={{
              color: '#1A5AFF'
            }}
          >
            Dashboard
          </TopBarNavLink>
          <div style={{ width: 40 }} />
          <TopBarNavLink
            to="/transactions/mints"
            activeStyle={{
              color: '#1A5AFF'
            }}
          >
            Transactions
          </TopBarNavLink>
        </TopBarNav>
      }
      {person &&
        <TopBarProfile person={person} organization={organization} />
      }
    </TopBarContainer>
  );
}

export { TopBar };

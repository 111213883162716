import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { getCookie } from 'lib/cookie';

export function createClient() {
  const cache = new InMemoryCache();

  const csrfMiddleware = new ApolloLink((operation, forward) => {
    // Send CSRF token
    const csrfToken = getCookie('csrf_token');
    operation.setContext({
      headers: {
        'CSRF-Token': csrfToken,
      },
    });
    return forward(operation);
  });

  const httpLink = new HttpLink({
    credentials: 'same-origin',
    uri: '/api/graphql',
  });

  // call needs to happen only if client side render
  cache.restore((window as any).__APOLLO_STATE__);

  return new ApolloClient({
    cache,
    link: ApolloLink.from([
      csrfMiddleware,
      httpLink,
    ]),
  });
}

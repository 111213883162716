import React from "react";
import Lottie from "lottie-react";
import styled from "styled-components";
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ minheight }) => minheight ? `min-height: 100vh` : `min-height: auto`}
  background: #fff;
  justify-content: center;
`;
const Loading = (props) => {
  const { fullpage } = props
  return (
    <ContentWrapper minheight={fullpage}>
      <Lottie
        animationData={require("json/lottiefiles-loading.json")}
        loop
        autoplay
        style={{ height: "100px" }}
      />
    </ContentWrapper>
  );
};

export default Loading;

import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const load = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const PageLoadSpinner = styled.div`
  font-size: 10px;
  margin: 0px auto;
  text-indent: -9999em;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(to right, ${({ theme }) => theme.colors.blue.default} 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: ${load} 1.4s infinite linear;
  animation: ${load} 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: ${({ theme }) => theme.colors.blue.default};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: rgb(246,248,252);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  ${({ whiteBg }) => whiteBg && `
    &:after {
      background: white;
    }
  `}
`;

function PageLoader(props) {
  return (
    <PageLoadSpinner {...props} />
  );
}

export { PageLoader };

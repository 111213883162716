import styled from 'styled-components';

const PageDescription = styled.p`
  color: ${({ theme }) => theme.colors.neutral.darker};
  max-width: 375px;
  margin: 0 auto;
  text-align: center;

  ${({ emphasized }) => emphasized && `
    font-size: 18px;
    font-weight: 400;
    max-width: 475px;
  `}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ textAlign }) => textAlign && `text-align ${textAlign};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
`;

export { PageDescription };

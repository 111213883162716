import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from 'lib/auth';
import queryString from 'query-string';
import { applicationState, customerState } from 'data/state';

function skipRouteProtection(props) {
  // for local dev, skip route protection with a cookie
  const queryArgs = queryString.parse(props.location.search);

  return (
    process.env.NODE_ENV !== 'production' &&
    queryArgs.develop === '1'
  );
}

// inspired by https://tylermcginnis.com/react-router-protected-routes-authentication/
function ProtectedRoute({
  component,       // rename to Component?
  componentProps = {},
  authenticated = false,
  loginDetection = false,
  emailVerified = false,
  accountActive = false,
  canTransact = false,
  applicationStage = null,
  trusttoken = null,
  approved = null,
  ...rest
}) {
  const Component = component;
  return (
    <Route
      {...rest}
      render={(props) => {
        // local dev skip protection
        if (skipRouteProtection(props)) {
          return <Component {...props} {...componentProps} />;
        }

        if (loginDetection && isAuthenticated()) {
          return <Redirect to="/" />;
        }

        if (authenticated && !isAuthenticated()) {
          return <Redirect to="/signup-or-signin" />;
        }

        if (emailVerified && !applicationState.emailVerified) {
          return <Redirect to="/" />;
        }

        if (accountActive && !customerState.isAccountActive) {
          return <Redirect to="/" />;
        }

        if (approved && !(applicationState.stage === 'approved')) {
          return <Redirect to="/" />;
        }

        if (canTransact && !customerState.canTransact) {
          return <Redirect to="/not-authorized" />;
        }

        return <Component {...props} {...componentProps} />;
      }}
    />
  );
}

const AuthenticatedRoute = (props) => (
  <ProtectedRoute authenticated {...props} />
);

const EmailVerifiedRoute = (props) => (
  <ProtectedRoute emailVerified {...props} />
);

const ApprovedRoute = (props) => (
  <ProtectedRoute ApprovedRoute {...props} />
);
export {
  ProtectedRoute,
  AuthenticatedRoute,
  EmailVerifiedRoute,
  ApprovedRoute,
};
